import * as React from "react"

const Lunch6 = () => (
  <div className="text-xl">
    <p>
      <em className="text-2xl">109 kr</em>
    </p>
    <p>
      Onsdag - fredag <em>11:30 - 14:30</em>
    </p>

    <p>
      <b>Onsdag</b>
      <br /> Helstekt kotlett med pepparsås
      <br />
      Stekt rödspätta med dansk remouladsås
      <br />
      Pasta med pepparsalami, grädde & parmesan
    </p>

    <p>
      <b>Torsdag</b>
      <br />
      Pannbiff med köttsky & stekt lök
      <br />
      Stekt koljafilé med vitvinssås
      <br />
      Pasta med fläskkött, pesto & grädde
    </p>

    <p>
      <b>Fredag</b>
      <br />
      Fläskfilé Black & White
      <br />
      Fiskgratäng med skaldjur & hummerfond
      <br />
      Pasta med kyckling & tomat- & gräddsås
    </p>

    <p>
      <b>Veckans vegetariska</b>
      <br />
      Falafel med tomatsås
    </p>

    <p>
      <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
      <br />
      Avhämtning: 99 kr
    </p>
  </div>
)

export default Lunch6
