import React from "react"
import Lunch1 from "../components/lunches/lunch1"
import Lunch2 from "../components/lunches/lunch2"
import Lunch3 from "../components/lunches/lunch3"
import Lunch4 from "../components/lunches/lunch4"
import Lunch5 from "../components/lunches/lunch5"
import Lunch6 from "../components/lunches/lunch6"
import Lunch7 from "../components/lunches/lunch7"
import Lunch8 from "../components/lunches/lunch8"
import LunchSpecial from "../components/lunches/lunch-special"
import Layout from "../components/layout"

const components = {
  lunch1: Lunch1,
  lunch2: Lunch2,
  lunch3: Lunch3,
  lunch4: Lunch4,
  lunch5: Lunch5,
  lunch6: Lunch6,
  lunch7: Lunch7,
  lunch8: Lunch8,
  lunchSpecial: LunchSpecial,
}

const Lunch = () => {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-4">Lunchmeny v. 37</div>

          <Lunch6 />

          <p>
            <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
            <br />
            Avhämtning: 99 kr
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Lunch
